<script>
	export let name;
</script>

<main>
	<!-- svelte-ignore a11y-missing-attribute -->
	<img size="" src="/images/langracetech_logo.png" height="50%" width="10%">

	<h1>宜蘭方舟聖教會 建堂專案</h1>
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>